import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FileSearchOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import axiosConfig from '../../utils/axios-config';
import cookies from 'js-cookie';
import { Countries } from '../../constants/country';
import {
    Row,
    Col,
    Card,
    Form,
    Spin,
    Table,
    Input,
    Button,
    Popconfirm,
    Modal,
    Select,
    Divider,
    InputNumber,
    DatePicker,
    message
} from 'antd';
import { delay } from '../../helpers/helpers';
import './licenses-tab.scss';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CreateLicenseRoles, UpdateLicenseRoles } from '../../constants/roles';

const { Option } = Select;
const { RangePicker } = DatePicker;

const LicensesTab = () => {

    const params = useParams();
    const [filteredData, setFilteredData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({});
    const [departments, setDepartments] = useState([]);
    const currentUser = useSelector((state) => state.auth.currentUser);
    var ocrRoleName = currentUser?.ocrRole?.name;
    var ocrRolePower = currentUser?.ocrRole?.power;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [currentLicense, setCurrentLicense] = useState({});

    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const showCreateModal = () => {
        setCreateModalOpen(true);
    };

    const handleCreateOk = () => {
        createForm.submit();
    };

    const handleCreateCancel = () => {
        setCreateModalOpen(false);
    };

    const showUpdateModal = async (record) => {
        console.log("Edit Record", record);
        setCurrentLicense(record);
        updateForm.setFieldsValue(record);
        setUpdateModalOpen(true);
    };

    const handleUpdateOk = () => {
        updateForm.submit();
    };

    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };

    useEffect(() => {
        document.title = 'Roxus | Manage Licenses';
        fetchTable();
        fetchDepartments();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const createLicense = async (values) => {
        console.log('Create License: ', values);
        let licenseDate = values.licenseDate;
        let licenseStartDate = licenseDate[0];
        let licenseStartDateStr = licenseStartDate.format("YYYY-MM-DD");
        let licenseEndDate = licenseDate[1];
        let licenseEndDateStr = licenseEndDate.format("YYYY-MM-DD");

        console.log("Start Date", licenseStartDateStr);
        console.log("End Date", licenseEndDateStr);

        let createLicenseRequest = {};
        createLicenseRequest.licenseType = values.licenseType;
        createLicenseRequest.documentUsers = values.documentUsers;
        createLicenseRequest.documentPages = values.documentPages;
        createLicenseRequest.documentModels = values.documentModels;
        createLicenseRequest.documentStartDate = licenseStartDateStr;
        createLicenseRequest.documentExpiredDate = licenseEndDateStr;
        try {
            setCreateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/companies/${params.companyId}/licenses`,
                data: createLicenseRequest,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Create License SUCCESSFULLY!');
            await fetchTable();
            setCreateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setCreateLoading(false);
        }
    };

    const updateLicense = async (values) => {
        console.log('Update License: ', values);
        let licenseDate = values.licenseDate;
        let licenseStartDate = licenseDate[0];
        let licenseStartDateStr = licenseStartDate.format("YYYY-MM-DD");
        let licenseEndDate = licenseDate[1];
        let licenseEndDateStr = licenseEndDate.format("YYYY-MM-DD");

        console.log("Start Date", licenseStartDateStr);
        console.log("End Date", licenseEndDateStr);

        let updateLicenseRequest = {};
        updateLicenseRequest.licenseType = values.licenseType;
        updateLicenseRequest.documentUsers = values.documentUsers;
        updateLicenseRequest.documentPages = values.documentPages;
        updateLicenseRequest.documentModels = values.documentModels;
        updateLicenseRequest.documentStartDate = licenseStartDateStr;
        updateLicenseRequest.documentExpiredDate = licenseEndDateStr;

        try {
            setUpdateLoading(true);
            const response = await axiosConfig({
                url: `/api/settings/licenses/${currentLicense.key}`,
                data: updateLicenseRequest,
                method: 'put',
                headers: {
                    Authorization: `Bearer ${cookies.get('access_token')}`,
                },
            });
            console.log(`response`, response);
            message.success('Update License SUCCESSFULLY!');
            await fetchTable();
            setUpdateModalOpen(false);
        } catch (error) {
            console.log(`error`, error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                message.error(error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                message.error(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                message.error(error.message);
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    const fetchDepartments = async () => {
        await axiosConfig({
            method: 'get',
            url: `/api/settings/companies/${params.companyId}/departments`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log(response);
                const departments = response.data.data.map((res, index) => {
                    return {
                        key: res.id,
                        name: res.name
                    };
                });
                console.log('Departments', departments);
                setDepartments(departments);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                setLoading(false);
                console.log(error.config);
            });
    };

    const fetchTable = async () => {
        await axiosConfig({
            method: 'get',
            url: `/api/settings/companies/${params.companyId}/licenses`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log(response);
                const data = response.data.data.map((res, index) => {
                    index++;
                    return {
                        key: res.id,
                        no: res.id,
                        licenseType: res.licenseType,
                        documentUsers: res.documentUsers,
                        documentPages: res.documentPages,
                        documentModels: res.documentModels,
                        documentStartDate: res.documentStartDate,
                        documentExpiredDate: res.documentExpiredDate,
                        createdTime: res.createdTime,
                        modifiedTime: res.modifiedTime,
                    };
                });
                console.log('Table Data', data);
                setTableData(data);
                setFilteredData(data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                setLoading(false);
                console.log(error.config);
            });
    };

    const deleteLicense = async (licenseId) => {
        await axiosConfig({
            method: 'delete',
            url: `/api/settings/licenses/${licenseId}`,
            headers: {
                Authorization: `Bearer ${cookies.get('access_token')}`,
            },
        })
            .then((response) => {
                console.log(response);
                fetchTable();
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };

    const applySearchCriteria = () => {
        let filterData = tableData;
        if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
            filterData = filterData.filter(
                (row) =>
                    row.Name
                        .toLowerCase()
                        .indexOf(searchCriteria.keyword.toLowerCase()) > -1
            );
            setFilteredData(filterData);
        }
        setFilteredData(filterData);
    };

    const filterByKeyword = (e) => {
        delay(function () {
            setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
        }, 500);
    };

    const handleTargetChange = (value) => {
        console.log("Target", value);
    }

    const columns = [
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <>
                        {ocrRoleName && UpdateLicenseRoles.includes(ocrRoleName) &&
                            <Button onClick={async () => await showUpdateModal(record)}>
                                Edit
                            </Button>
                        }
                        {/* <Popconfirm
							placement='top'
							title={'Are you sure to delete this department?'}
							onConfirm={() => deleteDepartment(record.key)}
							okText='Yes'
							cancelText='No'
						>
							<Button type='danger'>Delete</Button>
						</Popconfirm> */}
                    </>
                );
            },
        },
        {
            title: 'License Type',
            dataIndex: 'licenseType',
            key: 'licenseType',
        },
        {
            title: 'Number of Users',
            dataIndex: 'documentUsers',
            key: 'documentUsers',
        },
        {
            title: 'Number of Pages',
            dataIndex: 'documentPages',
            key: 'documentPages',
        },
        {
            title: 'Number of Models',
            dataIndex: 'documentModels',
            key: 'documentModels',
        },
        {
            title: 'Start Date',
            dataIndex: 'documentStartDate',
            key: 'documentStartDate',
            render: (text) => {
                return (
                    text && new Date(text).toISOString().substr(0, 10)
                );
            },
        },
        {
            title: 'Expired Date',
            dataIndex: 'documentExpiredDate',
            key: 'documentExpiredDate',
            render: (text) => {
                return (
                    text && new Date(text).toISOString().substr(0, 10)
                );
            },
        },
        {
            title: 'Created Time',
            key: 'createdTime',
            dataIndex: 'createdTime',
            render: (text) => {
                return (
                    text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
                );
            },
        },
        {
            title: 'Modified Time',
            key: 'modifiedTime',
            dataIndex: 'modifiedTime',
            render: (text) => {
                return (
                    text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
                );
            },
        },
    ];

    return (
        <>
            <Spin spinning={loading}>
                <Row className='search-area' gutter={{ lg: 8 }}>
                    <Col span={8}>
                        {ocrRoleName && CreateLicenseRoles.includes(ocrRoleName) &&
                            <Button type='primary' onClick={showCreateModal}>
                                Create License
                            </Button>
                        }
                    </Col>
                    <Col span={8} offset={8}>
                        <Input
                            id='search-box'
                            onKeyUp={filterByKeyword}
                            addonBefore={<SearchOutlined />}
                            placeholder='Search'
                        />
                    </Col>
                </Row>
                <Table columns={columns} dataSource={filteredData} />
            </Spin>
            <Form
                {...formItemLayout}
                form={createForm}
                name='register'
                onFinish={createLicense}
                scrollToFirstError
                loading={createLoading}
            >
                <Modal title="Create License" visible={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel} okText="Create License">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={createLoading}>
                                <Row>
                                    <Col span={24}>
                                        {/* <Form.Item
                                            colon={false}
                                            name='target'
                                            label='Target'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select Target',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                                onChange={handleTargetChange}
                                            >
                                                <Option value="Company">Company</Option>
                                                <Option value="Department">Department</Option>
                                            </Select>
                                        </Form.Item> */}
                                        {/* <Form.Item
                                            colon={false}
                                            name='departmentId'
                                            label='Department'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select Department',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                            >
                                                {departments && departments.length > 0 && departments.map(d => {
                                                    return <Option key={d.key} value={d.key}>{d.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item> */}
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='licenseType'
                                            label='License Type'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select License Type',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                            >
                                                <Option value="Documents">Documents</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentUsers'
                                            label='Number of Users'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Users!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentPages'
                                            label='Number of Credits'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Credits!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentModels'
                                            label='Number of Models'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Models!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='licenseDate'
                                            label='License Date'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input License Start Date!',
                                                },
                                            ]}
                                        >
                                            <RangePicker
                                                format="YYYY-MM-DD"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
            <Form
                {...formItemLayout}
                form={updateForm}
                name='register'
                onFinish={updateLicense}
                scrollToFirstError
                loading={updateLoading}
            >
                <Modal title="Update License" visible={updateModalOpen} onOk={handleUpdateOk} onCancel={handleUpdateCancel} okText="Update License">
                    <Row>
                        <Col span={24}>
                            <Spin spinning={updateLoading}>
                                <Row>
                                    <Col span={24}>
                                        {/* <Form.Item
                                            colon={false}
                                            name='target'
                                            label='Target'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select Target',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                                onChange={handleTargetChange}
                                            >
                                                <Option value="Company">Company</Option>
                                                <Option value="Department">Department</Option>
                                            </Select>
                                        </Form.Item> */}
                                        {/* <Form.Item
                                            colon={false}
                                            name='departmentId'
                                            label='Department'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select Department',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                            >
                                                {departments && departments.length > 0 && departments.map(d => {
                                                    return <Option key={d.key} value={d.key}>{d.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item> */}
                                        <Form.Item
                                            className=''
                                            colon={false}
                                            name='licenseType'
                                            label='License Type'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select License Type',
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                allowClear
                                            >
                                                <Option value="Documents">Documents</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentUsers'
                                            label='Number of Users'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Users!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentPages'
                                            label='Number of Credits'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Credits!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='documentModels'
                                            label='Number of Models'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input Number of Models!',
                                                },
                                            ]}
                                        >
                                            <InputNumber min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            name='licenseDate'
                                            label='License Date'
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input License Start Date!',
                                                },
                                            ]}
                                        >
                                            <RangePicker
                                                defaultValue={[moment(currentLicense.documentStartDate, "YYYY-MM-DD"), moment(currentLicense.documentExpiredDate, "YYYY-MM-DD")]}
                                                format="YYYY-MM-DD"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </>
    );
};

export default LicensesTab;
