import React, { useState, useEffect } from 'react';
import { Typography, Spin, Table, Tag, Space, Tabs, Row, Col, Form, Input, Select, Button, Modal, message } from 'antd';
import {
  CopyOutlined
} from '@ant-design/icons';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import parse from 'html-react-parser';
import { capitalize } from '../../utils/string-util';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './report-table.scss';

const ReportTable = () => {
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const currentUser = useSelector((state) => state.auth.currentUser);
  var ocrRoleName = currentUser?.ocrRole?.name;

  const [createForm] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id) {
      fetchTable().then((response) => {
        const data = response.data.data.map((res, index) => {
          index++;
          return {
            key: res.id,
            no: index,
            companyName: res.company?.name,
            reportName: res.reportName,
            createdTime: res.createdTime
          };
        });
        console.log('Table Data', data);
        if (isMounted) {
          setTableData(data);
          setLoading(false);
        }
      })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          if (isMounted) {
            setLoading(false);
          }
          console.log(error.config);
        });
    }
    return () => { isMounted = false };
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
  };

  const fetchTable = async () => {
    return await axiosConfig({
      method: 'get',
      url: `/api/settings/companyreports`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    })
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Link
            to={'companyreports/' + record.key}
            target='_blank'
            rel='noopener noreferrer'
          >
            View Details
          </Link>
        );
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Report Name',
      dataIndex: 'reportName',
      key: 'reportName'
    },
    {
      title: 'Created Time',
      key: 'createdTime',
      dataIndex: 'createdTime',
      render: (text) => {
        return (
          text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={tableData}
      />
    </Spin>
  );
};

export default ReportTable;
