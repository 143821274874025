export const OcrRoleNames = ['Validator', 'Trainer', 'Manager', 'Super-User', 'Admin', 'Super Admin'];
export const FileListRoles = ['Validator', 'Trainer', 'Manager', 'Super-User', 'Admin', 'Super Admin'];
export const UploadRoles = ['Trainer', 'Manager', 'Super-User', 'Admin', 'Super Admin'];
export const SettingRoles = ['Manager', 'Super-User', 'Admin'];
export const AllSettingRoles = ['Super Admin'];

// Company Information
// Department
export const CreateDepartmentRoles = ['Super-User', 'Admin', 'Super Admin'];
export const DeleteDepartmentRoles = ['Super-User', 'Admin', 'Super Admin'];
// User
export const CreateUserRoles = ['Super-User', 'Admin', 'Super Admin'];
export const DeleteUserRoles = ['Super-User', 'Admin', 'Super Admin'];
// License
export const CreateLicenseRoles = ['Admin', 'Super Admin'];
export const UpdateLicenseRoles = ['Admin', 'Super Admin'];