import React, { useState } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { Layout, Menu, Upload } from 'antd';
import {
	HomeOutlined,
	EyeOutlined,
	SoundOutlined,
	RobotOutlined,
	SettingOutlined,
	FileSearchOutlined,
	BarChartOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import AccountProfile from '../account-profile/account-profile';
import { FileListRoles, UploadRoles, SettingRoles, AllSettingRoles } from '../../constants/roles';
import './sidebar.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ selectedKey, openKey }) => {
	let history = useHistory();
	const currentUser = useSelector((state) => state.auth.currentUser);
	const [collapsed, setCollapsed] = useState(false);

	var isRoxusUser = false;
	isRoxusUser = currentUser && currentUser.email && currentUser.email.toLowerCase().includes("@roxus.io");
	var ocrRoleName = currentUser?.ocrRole?.name;
	var companyId = currentUser?.companyId;
	console.log("Current User", currentUser);

	const changePage = (params) => {
		switch (params.key) {
			case '1':
				history.push('/');
				break;
			case '3':
				history.push('/ocr/file-list');
				break;
			case '4':
				history.push('/ocr/upload');
				break;
			case 'event-list':
				history.push('/events');
				break;
			case 'upload-recording':
				history.push('/upload-recording');
				break;
			case 'tag-list':
				history.push('/tags');
				break;
			case 'assistant-stream':
				history.push('/streams');
				break;
			case 'process-config':
				history.push('/rpa/process-config');
				break;
			case 'task-history':
				history.push('/rpa/task-history');
				break;
			case 'get-otp':
				history.push('/rpa/get-otp');
				break;
			case 'main-logging':
				history.push('/rpa/main-logging');
				break;
			case 'pipeline':
				history.push('/pd/pipeline');
				break;
			case 'discovery':
				history.push('/pd/discovery');
				break;
			case 'companies':
				history.push('/settings/companies');
				break;
			case 'settings-company':
				history.push('/settings/companies/' + companyId);
				break;
			default:
				break;
		}
	};

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	return (
		<Sider
			width={260}
			breakpoint='lg'
			collapsedWidth='0'
			theme='light'
			collapsible
			collapsed={collapsed}
			onCollapse={toggleCollapsed}
		>
			<Link to='/account'>
				<AccountProfile
					fullName='Elon Musk'
					jobTitle='CEO'
				></AccountProfile>
			</Link>
			<Menu
				inlineIndent={24}
				defaultSelectedKeys={selectedKey}
				mode='inline'
				defaultOpenKeys={openKey}
			>
				{<Menu.Item key='1' onClick={changePage} icon={<HomeOutlined />}>
					Dashboard
				</Menu.Item>}
				{currentUser?.isActivated && ocrRoleName && ocrRoleName == "Super Admin" && (
					<SubMenu
						key='agents'
						icon={<SoundOutlined />}
						title='Automated.Agents'
					>
						{/* <Menu.Item key='8' onClick={changePage}>
              Dashboard
            </Menu.Item> */}
						<Menu.Item key='event-list' onClick={changePage}>
							Event List
						</Menu.Item>
						<Menu.Item key='tag-list' onClick={changePage}>
							Tag List
						</Menu.Item>
						<Menu.Item key='assistant-stream' onClick={changePage}>
							Assistant Streams
						</Menu.Item>
						<Menu.Item key='upload-recording' onClick={changePage}>
							Upload Recording
						</Menu.Item>
					</SubMenu>
				)}
				{currentUser?.isActivated && (currentUser.hasOcrLicense || ocrRoleName == "Super Admin") && (
					<SubMenu
						key='documents'
						icon={<EyeOutlined />}
						title='Automated.Documents'
					>
						{/* <Menu.Item key='10' onClick={changePage}>
							Dashboard
						</Menu.Item> */}
						{ocrRoleName && FileListRoles.includes(ocrRoleName) &&
							<Menu.Item key='3' onClick={changePage}>
								File List
							</Menu.Item>
						}
						{ocrRoleName && UploadRoles.includes(ocrRoleName) && <Menu.Item key='4' onClick={changePage}>
							Upload File
						</Menu.Item>}
						{/* <Menu.Item key='5' onClick={changePage}>
							Review File
						</Menu.Item> */}
					</SubMenu>
				)}
				{ocrRoleName && ocrRoleName == "Super Admin" && (
					<SubMenu
						key='rpa'
						icon={<RobotOutlined />}
						title='Automated.RPA'
					>
						{/* <Menu.Item key='10' onClick={changePage}>
							Dashboard
						</Menu.Item> */}
						<SubMenu key='configurations' title='Configurations'>
							<Menu.Item key='process-config' onClick={changePage}>
								Process Config
							</Menu.Item>
							{/* <Menu.Item key='task-trigger' onClick={changePage}>
								Task Trigger
							</Menu.Item> */}
							<Menu.Item key='task-history' onClick={changePage}>
								Task History
							</Menu.Item>
							<Menu.Item key='get-otp' onClick={changePage}>
								Get OTP
							</Menu.Item>
						</SubMenu>

						<Menu.Item key='main-logging' onClick={changePage}>
							Main Loggings
						</Menu.Item>
					</SubMenu>
				)}
				{currentUser?.isActivated && ocrRoleName == "Super Admin" && (
					<SubMenu
						key='pd'
						icon={<FileSearchOutlined />}
						title='Process Discovery'
					>
						<Menu.Item key='pipeline' onClick={changePage}>
							Pipeline
						</Menu.Item>
						<Menu.Item key='discovery' onClick={changePage}>
							Discovery
						</Menu.Item>
					</SubMenu>
				)}
				{ocrRoleName && SettingRoles.includes(ocrRoleName) && (
					<Menu.Item key='settings-company' onClick={changePage} icon={<SettingOutlined />}>
						Settings
					</Menu.Item>
				)}
				{ocrRoleName && AllSettingRoles.includes(ocrRoleName) && (
					<SubMenu
						key='settings'
						icon={<SettingOutlined />}
						title='Settings'
					>
						<Menu.Item key='companies' onClick={changePage}>
							Companies
						</Menu.Item>
					</SubMenu>
				)}
			</Menu>
		</Sider>
	);
};

export default withRouter(SideBar);
