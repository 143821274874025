import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import Iframe from '../../helpers/iframe';
import { useSelector } from 'react-redux';
import ReportTable from '../../components/company-report/report-table';
import './dashboard.scss';

const Dashboard = () => {

  const currentUser = useSelector((state) => state.auth.currentUser);
  var ocrRoleName = currentUser?.ocrRole?.name;

  var isRoxusUser = false;
  isRoxusUser = currentUser && currentUser.email && currentUser.email.toLowerCase().includes("@roxus.io");

  useEffect(() => {
    document.title = 'Roxus | Home';
  }, []);

  return (
    <>
      {currentUser?.isActivated && ocrRoleName == "Super Admin" &&
        (<div className="ocr-list-page">
          <div className="ocr-title">
            <span className='text'>COMPANY REPORTS</span>
          </div>
          <ReportTable />
        </div>)
      }

      {currentUser?.isActivated && currentUser?.company && currentUser?.company?.reportUrl && (
        <div className='reports'>
          <Iframe source={currentUser?.company?.reportUrl} />
        </div>
      )}
    </>


  );
};

export default Dashboard;
