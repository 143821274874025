import React, { useRef, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Form, Input, message, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import GraButton from '../button/gra-button';
import './signin-form.scss';
import SignUpModal from '../signup/signup';
import ActivateModal from '../activation/activate-modal';
import LogoImg from '../../assets/images/common/logo.png';
import axiosConfig from '../../utils/axios-config';
import { UserMessage } from '../../constants/user';
import useScript from '../../hooks/use-script';
import { setCurrentUser } from '../../redux/users';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

const LogInForm = ({ history }) => {
  const [signupVisible, setSignupVisible] = useState(false);
  const [activateVisible, setActivateVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();
  
  // useScript(
  //   `https://www.google.com/recaptcha/api.js?hl=en-GB&render=${process.env.REACT_APP_RECAPTCHA_KEY}`
  // );

  const toggleSignupModal = () => {
    setSignupVisible(!signupVisible);
  };

  const toggleActivateModal = () => {
    setActivateVisible(!activateVisible);
  };

  function onGoogleVerify(e) {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY, {
          action: 'submit',
        })
        .then((token) => {
          // Add your logic to submit to your backend server here.
          console.log('Token', token);
          let formData = new FormData();
          formData.append('token', token);

          axiosConfig({
            method: 'post',
            url: '/api/nanonets/recaptcha/verify',
            data: formData,
          })
            .then((response) => {
              console.log('Response', response);
              ref.current.submit();
            })
            .catch((error) => {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message.error(error.response.data);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                message.error(UserMessage.Login_500);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
        });
    });
  }

  const onSignupSuccess = (email) => {
    setEmail(email);
    toggleActivateModal();
  };

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);

    try {
      setLoading(true);
      const response = await axiosConfig.post('/api/nanonets/login', values);
      setLoading(false);
      console.log("Login Response", response);
      let currentUser = response.data.data;
      cookies.set('access_token', currentUser.accessToken);
      cookies.set('refresh_token', currentUser.refreshToken);
      dispatch(setCurrentUser(response.data));

      let refresh = {};
      refresh.accessToken = currentUser.accessToken;
      refresh.refreshToken = currentUser.refreshToken;
      const refreshResponse = await axiosConfig.post('/api/nanonets/refreshtoken', refresh);
      history.push('/');
    } catch (error) {
      setLoading(false);
      console.log(`error`, error);
      console.log(`error response`, error.response);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message.error(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        message.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        message.error(error.message);
      }
    }
  };

  return (
    <Row className='signin-form' justify='center' align='middle'>
      <div className='form-outline'>
        <Spin spinning={loading}>
          <div className='introduction'>
            <div className='intro-img'>
              <img src={LogoImg} alt='' />
            </div>
            <span className='intro'>AUTOMATED.PORTAL</span>
          </div>

          <Form
            ref={ref}
            name='normal-login'
            className='login-form'
            onFinish={onFinish}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className='site-form-item-icon' />}
                type='password'
                placeholder='Password'
              />
            </Form.Item>
            <Form.Item>
              <Link to='/forgot-password' className='login-form-forgot'>
                Forgot Password
              </Link>
            </Form.Item>
            <Form.Item>
              <GraButton
                className='login-form-button button-gra-inv'
                onClick={ref.submit}
              >
                Log in
              </GraButton>
              <br />
              <br />
              or{' '}
              <span onClick={toggleSignupModal} className='register-now'>
                Register Now
              </span>
            </Form.Item>
          </Form>
        </Spin>
      </div>
      <SignUpModal
        width={600}
        visible={signupVisible}
        onCancel={toggleSignupModal}
        onSuccess={onSignupSuccess}
      />
      <ActivateModal
        width={500}
        visible={activateVisible}
        onCancel={toggleActivateModal}
        footer={null}
        email={email}
        closable={false}
      />
    </Row>
  );
};

export default withRouter(LogInForm);
